//ACCOUNT
export const SET_USER_ACCOUNT = "SET_USER_ACCOUNT";
export const UPDATE_USER_ACCOUNT_BEGIN = "UPDATE_USER_ACCOUNT_BEGIN";
export const UPDATE_USER_ACCOUNT_SUCCESS = "UPDATE_USER_ACCOUNT_SUCCESS";
export const UPDATE_USER_ACCOUNT_ERROR = "UPDATE_USER_ACCOUNT_ERROR";
export const UPDATE_USER_ADDRESS_BEGIN = "UPDATE_USER_ADDRESS_BEGIN";
export const UPDATE_USER_ADDRESS_SUCCESS = "UPDATE_USER_ADDRESS_SUCCESS";
export const UPDATE_USER_ADDRESS_ERROR = "UPDATE_USER_ADDRESS_ERROR";
export const SET_USERNAME = "SET_USERNAME";
export const LOGOUT = "LOGOUT";
export const EMU_LOGOUT = "EMU_LOGOUT";
export const NU_LOGOUT = "NU_LOGOUT";
export const SET_EMU_LOGIN_STATUS = "SET_EMU_LOGIN_STATUS";
export const SET_NU_LOGIN_STATUS = "SET_NU_LOGIN_STATUS";
export const SET_SUBSCRIPTION_TO_MODIFY = "SET_SUBSCRIPTION_TO_MODIFY";
export const SET_RESERVATION_TO_MODIFY = "SET_RESERVATION_TO_MODIFY";
export const SET_SUBSCRIPTION_ID = "SET_SUBSCRIPTION_ID";
export const SET_QR_CODE_TOKEN = "SET_QR_CODE_TOKEN";
//PAYMENT METHODS
export const STORE_PAYMENT_METHOD = "STORE_PAYMENT_METHOD";
export const STORE_PURCHASE_TYPE = "STORE_PURCHASE_TYPE";
export const SAVE_PAYMENT_METHOD_BEGIN = "SAVE_PAYMENT_METHOD_BEGIN";
export const SAVE_PAYMENT_METHOD_SUCCESS = "SAVE_PAYMENT_METHOD_SUCCESS";
export const SAVE_PAYMENT_METHOD_ERROR = "SAVE_PAYMENT_METHOD_ERROR";
export const DELETE_PAYMENT_METHOD_BEGIN = "DELETE_PAYMENT_METHOD_BEGIN";
export const DELETE_PAYMENT_METHOD_SUCCESS = "DELETE_PAYMENT_METHOD_SUCCESS";
export const DELETE_PAYMENT_METHOD_ERROR = "DELETE_PAYMENT_METHOD_ERROR";
export const SET_DEFAULT_PAYMENT_METHOD_BEGIN =
  "SET_DEFAULT_PAYMENT_METHOD_BEGIN";
export const SET_DEFAULT_PAYMENT_METHOD_SUCCESS =
  "SET_DEFAULT_PAYMENT_METHOD_SUCCESS";
export const SET_DEFAULT_PAYMENT_METHOD_ERROR =
  "SET_DEFAULT_PAYMENT_METHOD_ERROR";
//VEHICLES
export const SAVE_VEHICLE_BEGIN = "SAVE_VEHICLE_BEGIN";
export const SAVE_VEHICLE_SUCCESS = "SAVE_VEHICLE_SUCCESS";
export const SAVE_VEHICLE_ERROR = "SAVE_VEHICLE_ERROR";
export const DELETE_VEHICLE_BEGIN = "DELETE_VEHICLE_BEGIN";
export const DELETE_VEHICLE_SUCCESS = "DELETE_VEHICLE_SUCCESS";
export const DELETE_VEHICLE_ERROR = "DELETE_VEHICLE_ERROR";
//PROGRAMS
export const SET_LOYALTY_PROGRAMS = "SET_LOYALTY_PROGRAMS";
export const SET_REWARD_PROGRAMS = "SET_REWARD_PROGRAMS";
export const DELETE_LOYALTY_PROGRAM = "DELETE_LOYALTY_PROGRAM";
