import { produce } from 'immer'

import {
  SAVE_VEHICLE_BEGIN,
  SAVE_VEHICLE_SUCCESS,
  SAVE_VEHICLE_ERROR,
  DELETE_VEHICLE_BEGIN,
  DELETE_VEHICLE_SUCCESS,
  DELETE_VEHICLE_ERROR,
  SET_USER_ACCOUNT,
  LOGOUT,
  SET_EMU_LOGIN_STATUS,
  SET_NU_LOGIN_STATUS,
  EMU_LOGOUT,
  NU_LOGOUT,
  SET_SUBSCRIPTION_TO_MODIFY,
  SET_RESERVATION_TO_MODIFY,
  SET_SUBSCRIPTION_ID,
  UPDATE_USER_ACCOUNT_BEGIN,
  UPDATE_USER_ACCOUNT_SUCCESS,
  UPDATE_USER_ACCOUNT_ERROR,
  UPDATE_USER_ADDRESS_BEGIN,
  UPDATE_USER_ADDRESS_SUCCESS,
  UPDATE_USER_ADDRESS_ERROR,
  STORE_PAYMENT_METHOD,
  STORE_PURCHASE_TYPE,
  SAVE_PAYMENT_METHOD_BEGIN,
  SAVE_PAYMENT_METHOD_SUCCESS,
  SAVE_PAYMENT_METHOD_ERROR,
  DELETE_PAYMENT_METHOD_BEGIN,
  DELETE_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD_ERROR,
  SET_DEFAULT_PAYMENT_METHOD_BEGIN,
  SET_DEFAULT_PAYMENT_METHOD_SUCCESS,
  SET_DEFAULT_PAYMENT_METHOD_ERROR,
  SET_QR_CODE_TOKEN,
  SET_LOYALTY_PROGRAMS,
  SET_REWARD_PROGRAMS,
  DELETE_LOYALTY_PROGRAM,
} from '../types/user.types'

const INITIAL_STATE = {
  fulfill: null,
  userAccount: null,
  userReservations: {
    reservations: [],
    reservationEmail: null,
    reservationId: null,
    reservationToModify: null,
  },
  userSubscriptions: {
    subscriptionToModify: null,
    subscriptionId: null,
  },
  userSpecialPrograms: {
    loyaltyPrograms: [],
    rewardPrograms: [],
  },
  isLoginViaNu: false,
  isLoginViaEmu: false,
  paymentMethod: null,
  purchaseType: null,
  QRCodeToken: null,
}

export { INITIAL_STATE }
export default produce((draft, action) => {
  switch (action.type) {
    case SET_USER_ACCOUNT:
      draft.userAccount = action.payload
      return draft
    case UPDATE_USER_ACCOUNT_BEGIN:
      return draft
    case UPDATE_USER_ACCOUNT_SUCCESS:
      draft.userAccount = action.payload
      return draft
    case UPDATE_USER_ACCOUNT_ERROR:
      return draft
    case UPDATE_USER_ADDRESS_BEGIN:
      return draft
    case UPDATE_USER_ADDRESS_SUCCESS:
      draft.userAccount = action.payload
      return draft
    case UPDATE_USER_ADDRESS_ERROR:
      return draft
    case SAVE_PAYMENT_METHOD_BEGIN:
      return draft
    case SAVE_PAYMENT_METHOD_SUCCESS:
      if (draft.userAccount?.paymentMethods) {
        draft.userAccount.paymentMethods = draft.userAccount.paymentMethods.map(
          (paymentMethod) => {
            return {
              ...paymentMethod,
              defaultInd: false,
            }
          }
        )
      } else {
        //If a user has never had a payment method on file we must first create the paymentMethods array.
        draft.userAccount.paymentMethods = []
      }
      draft.userAccount.paymentMethods.push(action.payload)
      return draft
    case SAVE_PAYMENT_METHOD_ERROR:
      return draft
    case DELETE_PAYMENT_METHOD_BEGIN:
      return draft
    case DELETE_PAYMENT_METHOD_SUCCESS:
      draft.userAccount.paymentMethods =
        draft.userAccount.paymentMethods.filter((paymentMethod) => {
          return paymentMethod.id !== action.payload
        })
      return draft
    case DELETE_PAYMENT_METHOD_ERROR:
      return draft
    case SET_DEFAULT_PAYMENT_METHOD_BEGIN:
      return draft
    case SET_DEFAULT_PAYMENT_METHOD_SUCCESS:
      draft.userAccount.paymentMethods = draft.userAccount.paymentMethods.map(
        (paymentMethod) => {
          if (paymentMethod.id !== action.payload.id) {
            return {
              ...paymentMethod,
              defaultInd: false,
            }
          } else {
            return {
              ...paymentMethod,
              defaultInd: true,
            }
          }
        }
      )
      return draft
    case SET_DEFAULT_PAYMENT_METHOD_ERROR:
      return draft
    case SET_QR_CODE_TOKEN:
      draft.QRCodeToken = action.payload
      return draft
    case LOGOUT:
      if (action.meta.clearAll) {
        localStorage.clear()
      }
      return INITIAL_STATE
    case SET_SUBSCRIPTION_ID:
      draft.userSubscriptions.subscriptionId = action.payload
      return draft
    case SET_SUBSCRIPTION_TO_MODIFY:
      draft.userSubscriptions.subscriptionToModify = action.payload
      return draft
    case SET_RESERVATION_TO_MODIFY:
      draft.userReservations.reservationToModify = action.payload
      return draft
    case SAVE_VEHICLE_BEGIN:
      return draft
    case SAVE_VEHICLE_SUCCESS:
      if (draft.userAccount?.extended?.fieldMap?.vehicles?.fieldList) {
        if (action.payload.fieldMap.defaultVehicle.value) {
          draft.userAccount.extended.fieldMap.vehicles.fieldList =
            draft.userAccount.extended.fieldMap.vehicles.fieldList.map(
              (vehicle) => {
                return {
                  ...vehicle,
                  fieldMap: {
                    ...vehicle.fieldMap,
                    defaultVehicle: {
                      ...vehicle.fieldMap.defaultVehicle,
                      value: false,
                    },
                  },
                }
              }
            )
        }

        const index =
          draft.userAccount.extended.fieldMap.vehicles.fieldList.findIndex(
            (vehicle) => {
              return (
                vehicle.fieldMap.vehicleId.value ===
                action.payload.fieldMap.vehicleId.value
              )
            }
          )
        if (index === -1) {
          draft.userAccount.extended.fieldMap.vehicles.fieldList.push(
            action.payload
          )
        } else {
          draft.userAccount.extended.fieldMap.vehicles.fieldList[index] =
            action.payload
        }
      } else {
        //If a user has never had a vehicle on file we must first create the extended object.
        draft.userAccount.extended = {
          fieldMap: { vehicles: { fieldList: [] } },
        }
        draft.userAccount.extended.fieldMap.vehicles.fieldList.push(
          action.payload
        )
      }
      return draft
    case SAVE_VEHICLE_ERROR:
      return draft
    case DELETE_VEHICLE_BEGIN:
      return draft
    case DELETE_VEHICLE_SUCCESS:
      draft.userAccount.extended.fieldMap.vehicles.fieldList =
        draft.userAccount.extended.fieldMap.vehicles.fieldList.filter(
          (vehicle) => {
            return vehicle.fieldMap.vehicleId.value !== action.payload
          }
        )
      return draft
    case DELETE_VEHICLE_ERROR:
      return draft
    case SET_EMU_LOGIN_STATUS:
      draft.isLoginViaEmu = action.payload ?? null
      return draft
    case SET_NU_LOGIN_STATUS:
      draft.isLoginViaNu = action.payload ?? null
      return draft
    case EMU_LOGOUT:
      draft.isLoginViaEmu = INITIAL_STATE.isLoginViaEmu
      return draft
    case NU_LOGOUT:
      draft.isLoginViaNu = INITIAL_STATE.isLoginViaNu
      return draft
    case STORE_PAYMENT_METHOD:
      draft.paymentMethod = action.payload ?? null
      return draft
    case STORE_PURCHASE_TYPE:
      draft.purchaseType = action.payload ?? null
      return draft
    case SET_LOYALTY_PROGRAMS:
      draft.userSpecialPrograms.loyaltyPrograms = action.payload ?? null
      return draft
    case SET_REWARD_PROGRAMS:
      draft.userSpecialPrograms.rewardPrograms = action.payload ?? null
      return draft
    case DELETE_LOYALTY_PROGRAM:
      draft.userSpecialPrograms.loyaltyPrograms =
        draft.userSpecialPrograms.loyaltyPrograms.filter((loyaltyProgram) => {
          return loyaltyProgram.id !== action.payload.id
        })
      return draft
    default:
      return draft
  }
}, INITIAL_STATE)
