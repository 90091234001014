import { produce } from "immer";

import {
  TOGGLE_TOAST_VISIBILITY,
  SET_SEARCH_WIDGET_VISIBILITY,
  SET_IS_FULL_SEARCH_WIDGET,
  SET_TOAST,
  SET_DISABLE_START_DATE_AND_TIME,
  SET_SEARCH_WIDGET_BACKGROUND_CLOSE_ENABLE,
} from "../actions";

export const INITIAL_STATE = {
  toast: {
    displayToast: false,
    title: null,
    message: "",
    delay: 5000,
    autohide: true,
    type: null,
    code: null,
  },
  searchWidgetVisible: false,
  isFullSearchWidget: true,
  disableStartDateAndTime: false,
  searchWidgetBackgroundCloseEnable: true,
};

export default produce((draft, action) => {
  switch (action.type) {
    case TOGGLE_TOAST_VISIBILITY:
      draft.toast.displayToast = !draft.toast.displayToast;
      return draft;
    case SET_TOAST:
      let title = action.payload.title;
      let message = action.payload.message;

      if (action.payload.type === "E" && !action.payload.code === "SUB0119") {
        title = "An error occurred...";
      } else if (action.payload.type === "S") {
        title = "Success";
      }

      if (action.payload.code === "SUB0119")
        message = `<p>Email already exists for account, please <a href="https://go.lazparking.com/login?type=login">Sign In</a>.</p>`;

      draft.toast.displayToast = !draft.toast.displayToast;
      draft.toast.title = title;
      draft.toast.message = message;
      draft.toast.type = action.payload.type;
      draft.toast.code = action.payload.code;
      draft.toast.delay = action.payload.delay;
      draft.toast.autohide = action.payload.autohide
        ? action.payload.autohide
        : draft.toast.autohide;
      return draft;
    case SET_SEARCH_WIDGET_VISIBILITY:
      draft.searchWidgetVisible = action.payload;
      return draft;
    case SET_IS_FULL_SEARCH_WIDGET:
      draft.isFullSearchWidget = action.payload;
      return draft;
    case SET_DISABLE_START_DATE_AND_TIME:
      draft.disableStartDateAndTime = action.payload;
      return draft;
    case SET_SEARCH_WIDGET_BACKGROUND_CLOSE_ENABLE:
      draft.searchWidgetBackgroundCloseEnable = !!action.payload;
      return draft;
    default:
      return draft;
  }
}, INITIAL_STATE);
