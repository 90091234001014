import moment from "moment";
import amexIcon from "../../assets/image/AMEX.svg";
import visaIcon from "../../assets/image/VISA.svg";
import mastercardIcon from "../../assets/image/Mastercard.svg";
import applePayIcon from "../../assets/image/ApplePay.svg";
import googlePayIcon from "../../assets/image/GooglePay.svg";
import discoverIcon from "../../assets/image/Discover.svg";
import padIcon from "../../assets/image/PAD ACH.svg";
import otherCardIcon from "../../assets/image/Other.svg";
import garageHome from "../../assets/image/garage_home.svg";
import accessible from "../../assets/image/accessible.svg";
import creditCard from "../../assets/image/credit_card.svg";
import motorcycle from "../../assets/image/motorcycle.svg";
import localAtm from "../../assets/image/local_atm.svg";
import localShipping from "../../assets/image/local_shipping.svg";
import schedule from "../../assets/image/schedule.svg";
import valetParking from "../../assets/image/valet_parking.svg";
import evCharger from "../../assets/image/ev_charger.svg";
import sun from "../../assets/image/sun.svg";
import iPhone from "../../assets/image/iphone.svg";
import baby from "../../assets/image/baby.svg";
import cable from "../../assets/image/cable.svg";
import tireRepair from "../../assets/image/tire_repair.svg";
import vehicleServices from "../../assets/image/car_repair.svg";
import restrooms from "../../assets/image/restrooms.svg";
import carWash from "../../assets/image/car_wash.svg";
import keys from "../../assets/image/keys.svg";

export default class DataUtil {
  static toFixed = (number, decimal) => {
    try {
      return parseFloat(number).toFixed(decimal);
    } catch (e) {
      return number;
    }
  };

  static convertArrayToLookupTable(data = [], key) {
    const map = {};
    data.forEach((datum) => {
      map[datum[key]] = datum;
    });
    return map;
  }

  static convertLookupTableToArray(map = {}) {
    const arr = [];
    for (let key in map) {
      if (map[key]) arr.push(map[key]);
    }
    return arr;
  }

  static buildUrlParams = (paramsMap) => {
    return Object.keys(paramsMap)
      .map((key) => key + "=" + paramsMap[key])
      .join("&");
  };

  static getInitials = (fullName) => {
    let nameArray = fullName ? fullName.split(" ") : [];
    let initials = "";

    if (nameArray[0]) initials += this.getInitial(nameArray[0]);
    if (nameArray[1]) initials += this.getInitial(nameArray[1]);

    return initials;
  };

  static getInitial = (name) => {
    if (name) return name.charAt(0);
    return "";
  };

  static getLocationRegion = (city, state, zip) => {
    return (
      (city ? city : "") +
      (state ? ", " : " ") +
      (state ? state : "") +
      (zip ? " " : " ") +
      (zip ? zip : "")
    );
  };

  static getDate = (date, format) => {
    if (!format) format = process.env.REACT_APP_DATE_FORMAT;
    return moment(date).format(format);
  };

  static getDateTime = (
    date = new Date(),
    format = process.env.REACT_APP_DATETIME_FORMAT
  ) => {
    return moment(date).format(format);
  };

  static getDateTimeShort = (date, format) => {
    if (!format) format = process.env.REACT_APP_DATETIME_SHORT_FORMAT;
    return moment(date).format(format);
  };

  static getTime = (date, format) => {
    if (!format) format = process.env.REACT_APP_TIME_FORMAT;
    return moment(date).format(format);
  };

  static get12TimeShort = (date, format) => {
    if (!format) format = process.env.REACT_APP_TIME_AM_PM_SHORT_FORMAT;
    return moment(date).format(format);
  };

  static getGRSApiDateTime = (date, format) => {
    if (!format) format = process.env.REACT_APP_GRS_CORE_API_DATE_FORMAT;
    return moment(date).format(format);
  };

  static filterActiveVehicles = (user) => {
    const vehicles = user?.extended?.fieldMap?.vehicles?.fieldList;
    if (vehicles?.length) {
      const activeVehicles = vehicles.filter((vehicle) => {
        if (vehicle?.fieldMap?.isDeleted === null) {
          return true;
        } else if (!vehicle?.fieldMap?.isDeleted?.value) {
          return true;
        } else if (vehicle?.fieldMap?.isDeleted?.value) {
          return false;
        }
      });
      return activeVehicles;
    } else {
      return [];
    }
  };

  static getDefaultPaymentMethod = (user) => {
    return (
      user?.paymentMethods?.filter(
        (paymentMethod) => paymentMethod?.defaultInd === true
      )[0] || user?.paymentMethods?.[0]
    );
  };

  static getAddress = (user, type, getFirstIfTypeNotFound) => {
    let address = user?.addresses?.filter(
      (address) => address?.type == type
    )?.[0];
    if (getFirstIfTypeNotFound && !address) address = user?.addresses?.[0];
    return address;
  };

  static getCreditCardIconName = (cardType) => {
    cardType = cardType?.toLowerCase();
    return (
      {
        visa: visaIcon,
        v: visaIcon,
        mastercard: mastercardIcon,
        m: mastercardIcon,
        amex: amexIcon,
        a: amexIcon,
        discover: discoverIcon,
        d: discoverIcon,
        other: otherCardIcon,
        n: otherCardIcon,
        checking: padIcon,
        businesschecking: padIcon,
        savings: padIcon,
        undefined: padIcon,
        apple_pay: applePayIcon,
        google_pay: googlePayIcon,
      }[cardType] || ""
    );
  };

  static getCardLast4 = (emvData) => {
    if (typeof emvData === "string") {
      return emvData.substr(emvData.length - 4);
    } else {
      let token = emvData?.token || "";
      return token.substr(token.length - 4);
    }
  };

  static getCardExpiryMonth = (emvData) => {
    if (typeof emvData === "string") {
      let tokenExpiry = emvData || "";
      const month = tokenExpiry.substr(4) || "";
      return this.getExpiryFullMonth(month);
    } else {
      let tokenExpiry = emvData?.expiryDate || "";
      const month = tokenExpiry.substr(4);
      return this.getExpiryFullMonth(month);
    }
  };

  static getExpiryFullMonth = (month = "") => {
    if (month.length === 2) {
      return month;
    } else {
      return ("0" + month).slice(-2);
    }
  };

  static getCardExpiryYear = (emvData) => {
    let tokenExpiry = emvData?.expiryDate || "";
    return tokenExpiry.substr(2, 2);
  };

  static getCardExpiryFullYear = (emvData) => {
    if (typeof emvData === "string") {
      let tokenExpiry = emvData || "";
      return tokenExpiry.substr(0, 4);
    } else {
      let tokenExpiry = emvData?.expiryDate || "";
      return tokenExpiry.substr(0, 4);
    }
  };

  static getExpiryShortYear = (year) => {
    return String(year || "").substr(2, 2);
  };

  static getExpiryFullYear = (year) => {
    return "20" + year;
  };

  static validateLicensePlate = (licensePlate) => {
    const string = (licensePlate = ("" + licensePlate).toUpperCase());
    const nonAlphanumeric = /[\W_]/g;
    const validPlateString = string.replace(nonAlphanumeric, "");
    return validPlateString;
  };

  static getVehicleTypes = () => {
    return [
      { value: "Motorcycle", label: "Motorcycle" },
      { value: "Full-sized", label: "Standard Vehicle" },
      { value: "Oversized", label: "Oversized Vehicle" },
    ];
  };

  static getStates = (options = {}) => {
    const { useOptGroup = false } = options;
    return [
      { countrycd: "US", value: "AL", label: "Alabama" },
      { countrycd: "US", value: "AK", label: "Alaska" },
      { countrycd: "US", value: "AZ", label: "Arizona" },
      { countrycd: "US", value: "AR", label: "Arkansas" },
      { countrycd: "US", value: "CA", label: "California" },
      { countrycd: "US", value: "CO", label: "Colorado" },
      { countrycd: "US", value: "CT", label: "Connecticut" },
      { countrycd: "US", value: "DE", label: "Delaware" },
      { countrycd: "US", value: "DC", label: "District Of Columbia" },
      { countrycd: "US", value: "FL", label: "Florida" },
      { countrycd: "US", value: "GA", label: "Georgia" },
      { countrycd: "US", value: "HI", label: "Hawaii" },
      { countrycd: "US", value: "ID", label: "Idaho" },
      { countrycd: "US", value: "IL", label: "Illinois" },
      { countrycd: "US", value: "IN", label: "Indiana" },
      { countrycd: "US", value: "IA", label: "Iowa" },
      { countrycd: "US", value: "KS", label: "Kansas" },
      { countrycd: "US", value: "KY", label: "Kentucky" },
      { countrycd: "US", value: "LA", label: "Louisiana" },
      { countrycd: "US", value: "ME", label: "Maine" },
      { countrycd: "US", value: "MD", label: "Maryland" },
      { countrycd: "US", value: "MA", label: "Massachusetts" },
      { countrycd: "US", value: "MI", label: "Michigan" },
      { countrycd: "US", value: "MN", label: "Minnesota" },
      { countrycd: "US", value: "MS", label: "Mississippi" },
      { countrycd: "US", value: "MO", label: "Missouri" },
      { countrycd: "US", value: "MT", label: "Montana" },
      { countrycd: "US", value: "NE", label: "Nebraska" },
      { countrycd: "US", value: "NV", label: "Nevada" },
      { countrycd: "US", value: "NH", label: "New Hampshire" },
      { countrycd: "US", value: "NJ", label: "New Jersey" },
      { countrycd: "US", value: "NM", label: "New Mexico" },
      { countrycd: "US", value: "NY", label: "New York" },
      { countrycd: "US", value: "NC", label: "North Carolina" },
      { countrycd: "US", value: "ND", label: "North Dakota" },
      { countrycd: "US", value: "OH", label: "Ohio" },
      { countrycd: "US", value: "OK", label: "Oklahoma" },
      { countrycd: "US", value: "OR", label: "Oregon" },
      { countrycd: "US", value: "PA", label: "Pennsylvania" },
      { countrycd: "US", value: "RI", label: "Rhode Island" },
      { countrycd: "US", value: "SC", label: "South Carolina" },
      { countrycd: "US", value: "SD", label: "South Dakota" },
      { countrycd: "US", value: "TN", label: "Tennessee" },
      { countrycd: "US", value: "TX", label: "Texas" },
      { countrycd: "US", value: "UT", label: "Utah" },
      { countrycd: "US", value: "VT", label: "Vermont" },
      { countrycd: "US", value: "VA", label: "Virginia" },
      { countrycd: "US", value: "WA", label: "Washington" },
      { countrycd: "US", value: "WV", label: "West Virginia" },
      { countrycd: "US", value: "WI", label: "Wisconsin" },
      { countrycd: "US", value: "WY", label: "Wyoming" },
      {
        value: useOptGroup ? "-----CANADA-----" : "",
        label: useOptGroup ? "Canada" : "-----CANADA-----",
        disabled: true,
      },
      { countrycd: "CA", value: "AB_CANADA", label: "Alberta" },
      { countrycd: "CA", value: "BC_CANADA", label: "British Columbia" },
      { countrycd: "CA", value: "MB_CANADA", label: "Manitoba" },
      { countrycd: "CA", value: "NB_CANADA", label: "New Brunswick" },
      {
        countrycd: "CA",
        value: "NL_CANADA",
        label: "Newfoundland and Labrador",
      },
      { countrycd: "CA", value: "NS_CANADA", label: "Nova Scotia" },
      { countrycd: "CA", value: "NT_CANADA", label: "Northwest Territories" },
      { countrycd: "CA", value: "NU_CANADA", label: "Nunavut" },
      { countrycd: "CA", value: "ON_CANADA", label: "Ontario" },
      { countrycd: "CA", value: "PE_CANADA", label: "Prince Edward Island" },
      { countrycd: "CA", value: "QC_CANADA", label: "Quebec" },
      { countrycd: "CA", value: "SK_CANADA", label: "Saskatchewan" },
      { countrycd: "CA", value: "YT_CANADA", label: "Yukon" },
    ];
  };

  static getCountry = (state) => {
    let countryCd = "";
    //for now only us and canada
    const states = this.getStates();
    const stateObj = states.filter((s) => s.value === state);
    if (state && stateObj.length) {
      //US
      countryCd = "US";
    } else if (state) {
      countryCd = "CA";
    }
    return countryCd;
  };

  static getAmenityIcon = (label) => {
    // https://fonts.google.com/icons
    switch (label) {
      case "On-Site Vehicle Service":
        return <img src={vehicleServices} alt={label} />;
      case "Public Restrooms":
        return <img src={restrooms} alt={label} />;
      case "On-Site Car Wash":
        return <img src={carWash} alt={label} />;
      case "On-Site Vehicle Unlock Service":
        return <img src={keys} alt={label} />;
      case "Infant Parking":
        return <img src={baby} alt={label} />;
      case "Tire Inflation":
        return <img src={tireRepair} alt={label} />;
      case "Outdoor Self-Park":
        return <img src={sun} alt={label} />;
      case "Pay by Cell Phone":
        return <img src={iPhone} alt={label} />;
      case "Jump-Start Service":
        return <img src={cable} alt={label} />;
      case "Indoor Self-Park":
        return <img src={garageHome} alt={label} />;
      case "Electric Vehicle Charging":
        return <img src={evCharger} alt={label} />;
      case "Open 24/7":
        return <img src={schedule} alt={label} />;
      case "Valet Parking":
        return <img src={valetParking} alt={label} />;
      case "Motorcycle Parking":
        return <img src={motorcycle} alt={label} />;
      case "Oversized Vehicle Parking":
        return <img src={localShipping} alt={label} />;
      case "Handicap Accessible":
        return <img src={accessible} alt={label} />;
      case "Accepts Cash":
        return <img src={localAtm} alt={label} />;
      case "Accepts Credit Cards":
        return <img src={creditCard} alt={label} />;
      default:
        return null;
    }
  };

  static getAmenityIconLegacy = (amenity) => {
    return (
      {
        "Indoor Self-Park": <i className="fa fa-parking text-105 mr-15"></i>,
        "Motorcycle Parking": (
          <i className="fa fa-motorcycle text-105 mr-15"></i>
        ),
        "Oversized Vehicle Parking": (
          <i className="fa fa-truck text-105 mr-15"></i>
        ),
        "Handicap Accessible": (
          <i className="fa fa-wheelchair text-105 mr-15"></i>
        ),
        "Accepts Cash": <i className="fa fa-money-bill text-105 mr-15"></i>,
        "Accepts Credit Cards": (
          <i className="fa fa-credit-card text-105 mr-15"></i>
        ),
      }[amenity] || amenity
    );
  };

  static dollarFormatter = (amount = 0) => {
    try {
      if (typeof amount === "string") amount = parseInt(amount);

      const decimalPoints = 2;
      const fixedAmount = amount.toFixed(decimalPoints);
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: decimalPoints,
      });
      return formatter.format(parseFloat(fixedAmount));
    } catch (error) {
      console.warn(`Unable to parse dollar formatting for ${amount}`, error);
      return `$${amount}`;
    }
  };

  static generateNewVehicleKey = (
    parkerVehicleId,
    parkerLicensePlate,
    parkerVehicleMake,
    parkerVehicleModel
  ) => {
    return `${parkerVehicleId}_${parkerLicensePlate}_${parkerVehicleMake}_${parkerVehicleModel}`;
  };

  static generateKey = (prefix) => {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    if (!prefix) return `${uuid}`;
    else return `${prefix}_${uuid}`;
  };

  static constructAccountObject = (
    firstName,
    lastName,
    email,
    password,
    vehicles,
    paymentMethods
  ) => {
    let account = {
      owner: {
        firstName: firstName,
        lastName: lastName,
        contact: {
          email: [{ email: email, mode: "EMAIL" }],
          preferred: { mode: "EMAIL", primary: true },
        },
      },
      name: firstName + " " + lastName,
      type: "INDIVIDUAL",
      users: [
        {
          userId: email,
          email: email,
          firstName: firstName,
          lastName: lastName,
          srcInternal: false,
        },
      ],
      contacts: [
        {
          email: email,
          firstName: firstName,
          lastName: lastName,
          contactType: "BILLING",
        },
      ],
      password: password,
    };
    if (vehicles)
      account.extended = { fieldMap: { vehicles: { fieldList: vehicles } } };
    if (paymentMethods) account.paymentMethods = paymentMethods;
    return account;
  };

  static deconstructVehicleObject = (vehicle) => {
    return {
      parkerVehicleId: vehicle?.fieldMap?.vehicleId?.value,
      parkerVehicleType: vehicle?.fieldMap?.vehicleType?.value,
      parkerVehicleMake: vehicle?.fieldMap?.make?.value,
      parkerVehicleModel: vehicle?.fieldMap?.model?.value,
      parkerVehicleYear: vehicle?.fieldMap?.year?.value,
      parkerVehicleColor: vehicle?.fieldMap?.color?.value,
      parkerLicensePlate: vehicle?.fieldMap?.plateNumber?.value,
      parkerLicensePlateCountry: vehicle?.fieldMap?.country?.value,
      parkerLicensePlateState: vehicle?.fieldMap?.state?.value,
      defaultVehicle: vehicle?.fieldMap?.defaultVehicle?.value,
    };
  };

  static constructVehicleObject = (
    vehicleId = DataUtil.generateKey(),
    vehicleType,
    make,
    model,
    color,
    plateNumber,
    country,
    state,
    defaultVehicle,
    isRentalVehicle = false
  ) => {
    return {
      inactive: false,
      code: "vehicle",
      fieldMap: {
        vehicleId: {
          code: "vehicleId",
          inactive: false,
          value: vehicleId || null,
        },
        rental: {
          code: "isRentalVehicle",
          value: isRentalVehicle,
        },
        vehicleType: {
          code: "vehicleType",
          inactive: false,
          value: vehicleType || null,
        },
        make: {
          code: "make",
          inactive: false,
          value: make || null,
        },
        model: {
          code: "model",
          inactive: false,
          value: model || null,
        },
        color: {
          code: "color",
          inactive: false,
          value: color || null,
        },
        plateNumber: {
          code: "plateNumber",
          inactive: false,
          value: plateNumber || "",
        },
        country: {
          code: "country",
          inactive: false,
          value: country || null,
        },
        state: {
          code: "state",
          inactive: false,
          value: state || null,
        },
        dateAdded: {
          code: "dateAdded",
          inactive: false,
          value: DataUtil.getDateTime() || null,
        },
        defaultVehicle: {
          code: "defaultVehicle",
          inactive: false,
          value: !!defaultVehicle,
        },
      },
    };
  };

  static constructPaymentMethodObject = (
    cardType,
    nameOnCard,
    lastFour,
    expiryMonth,
    expiryFullYear,
    address,
    postalCode,
    token,
    defaultInd
  ) => {
    return {
      paymentMethodType: "CREDIT_CARD",
      cardType: cardType,
      nameOnCard: nameOnCard,
      lastFour: lastFour,
      expireMonth: expiryMonth,
      expireYear: expiryFullYear,
      address: address,
      postalCode: postalCode,
      token: token,
      defaultInd: defaultInd,
    };
  };

  static constructPADPaymentMethodObject = (
    achAccountType,
    nameOnCard,
    bankName,
    achAbaCode,
    achAccountNumber,
    token,
    defaultInd /*, id*/
  ) => {
    return {
      paymentMethodType: "PREAUTHORIZED_DEBIT",
      token: token,
      nameOnCard: nameOnCard,
      achAccountType: achAccountType,
      bankName: bankName,
      achBankName: bankName,
      achAccountNumber: achAccountNumber,
      achAccountName: nameOnCard,
      achAbaCode: achAbaCode,
      defaultInd: defaultInd,
    };
  };

  static getCardTypeFromProduct = (product) => {
    return (
      {
        A: "AMEX",
        V: "VISA",
        M: "MASTERCARD",
      }[product] || "OTHER"
    );
  };

  static calculatePriceDisplay(amount) {
    if (!amount) {
      return {
        amount: undefined,
        priceDisplay: "P",
      };
    }
    const price = this.toFixed(Math.round(amount), 0);
    return {
      amount: price,
      priceDisplay: price ? `$${price}` : "P",
    };
  }

  static getTotalDuration(startParkingDate, endParkingDate) {
    const startDate = new Date(startParkingDate);
    const endDate = new Date(endParkingDate);

    // Calculate the difference in milliseconds
    const diffInMilli = endDate - startDate;

    // Convert milliseconds to minutes
    const diffInMins = Math.floor(diffInMilli / (1000 * 60)); // Convert milliseconds to minutes

    // Calculate days
    const dayDiff = Math.floor(diffInMins / (24 * 60));

    // Calculate remaining hours
    const hrDiff = Math.floor((diffInMins % (24 * 60)) / 60);

    // Calculate remaining minutes
    const minDiff = diffInMins % 60;

    let daysString = dayDiff > 0 ? dayDiff + "d" : "";
    let hrsString = hrDiff > 0 ? hrDiff + "h" : "0h";
    let minsString = minDiff > 0 ? minDiff + "m" : "0m";

    let durationString = daysString + " " + hrsString + " " + minsString;

    return durationString;
  }

  static getCurrentDuration(startParkingDate) {
    const startDate = new Date(startParkingDate);
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const diffInMilli = currentDate - startDate;

    // Convert milliseconds to minutes
    const diffInMins = Math.floor(diffInMilli / (1000 * 60)); // Convert milliseconds to minutes

    // Calculate days
    const dayDiff = Math.floor(diffInMins / (24 * 60));

    // Calculate remaining hours
    const hrDiff = Math.floor((diffInMins % (24 * 60)) / 60);

    // Calculate remaining minutes
    const minDiff = diffInMins % 60;

    let daysString = dayDiff > 0 ? dayDiff + "d" : "";
    let hrsString = hrDiff > 0 ? hrDiff + "h" : "0h";
    let minsString = minDiff > 0 ? minDiff + "m" : "0m";

    let durationString = daysString + " " + hrsString + " " + minsString;

    return durationString;
  }

  static formatDateTime = (parkingDate) => {
    const date = new Date(parkingDate);
    const month = date.toLocaleString(undefined, { month: "short" });
    const day = date.getDate();
    const year = date.getFullYear();
    let hour = date.getHours();
    let minutes = date.getMinutes();

    if (minutes < 10) minutes = minutes + "0";

    const timeOfDay = hour >= 12 ? "PM" : "AM";

    hour = hour % 12 || 12;

    const formatted = `${month} ${day}, ${year} ${hour}:${minutes} ${timeOfDay}`;

    return formatted;
  };

  static calculateFeeTotal = (receiptData) => {
    let fees = [];
    let feesTotal = 0;

    if (receiptData) {
      receiptData.Fees.forEach((fee) => fees.push(fee.Amount));
    }

    for (let i = 0; i < fees.length; i++) {
      feesTotal += fees[i];
    }

    return feesTotal;
  };

  static isAppleDevice = () => {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
        "MacIntel",
        "Mac68k",
        "MacPPC",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  };

  static applyAlphaToRgb = (r, g, b, alpha) => {
    if (
      !(
        r >= 0 &&
        r <= 255 &&
        g >= 0 &&
        g <= 255 &&
        b >= 0 &&
        b <= 255 &&
        alpha >= 0 &&
        alpha <= 1
      )
    ) {
      console.log(
        "Invalid input values. R, G, B must be between 0-255 and alpha must be between 0-1."
      );
      return "0,125,186";
    }

    const whiteR = 255,
      whiteG = 255,
      whiteB = 255;

    const resR = Math.round((1 - alpha) * whiteR + alpha * r);
    const resG = Math.round((1 - alpha) * whiteG + alpha * g);
    const resB = Math.round((1 - alpha) * whiteB + alpha * b);

    return resR + "," + resG + "," + resB;
  };

  static hexToRgb = (hex = "", alpha) => {
    if (!hex) {
      return "0,125,186";
    }
    hex = hex.replace(/^#/, "");
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    if (alpha) {
      return this.applyAlphaToRgb(r, g, b, alpha);
    } else {
      return r + "," + g + "," + b;
    }
  };
}
