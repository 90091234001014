import React, { Suspense, lazy } from "react";
import { Switch, useLocation, Route, Redirect } from "react-router-dom";
import qs from "querystring-es3";
import RestApi from "./components/Services/RestApi";
import BugsnagManager from "./BugsnagManager";
import { TABS as PAYG_TABS } from "./components/ReservationPages/PayAsYouGo/payg_routes";
import withGoogleServices from "./components/hoc/withGoogleServices";
import PageLoader from "./components/Common/PageLoader";
import "./index.scss";
import { getAirportClientFromPath } from "./components/ReservationPages/Airport/helpers";

const NotFoundPage = lazy(() => import("./components/NotFoundPage"));
const BasePage = lazy(() => import("./components/Layout/BasePage"));
const BasePaygPage = lazy(() => import("./components/Layout/BasePaygPage"));
const BaseAirportPage = lazy(
  () => import("./components/ReservationPages/Airport/BaseAirportPage")
);

const PayAsYouGo = lazy(
  () => import("./components/ReservationPages/PayAsYouGo")
);
const Lookup = lazy(() => import("./components/LoginPages/Lookup"));
const SearchResults = lazy(
  () => import("./components/ReservationPages/SearchResults")
);
const Airport = lazy(() => import("./components/ReservationPages/Airport"));
const ChangePassword = lazy(
  () => import("./components/ExternalReservationPages/ChangePassword")
);
const PaymentMethods = lazy(
  () => import("./components/AccountPages/PaymentMethods")
);
const SubNow = lazy(() => import("./components/ReservationPages/SubNow"));
const Vehicles = lazy(() => import("./components/AccountPages/Vehicles"));
const Subscriptions = lazy(
  () => import("./components/AccountPages/Subscriptions")
);
const Transactions = lazy(
  () => import("./components/AccountPages/Transactions")
);
const ExternalBuyNow = lazy(
  () => import("./components/ExternalReservationPages/ExternalBuyNow")
);
const BuyNow = lazy(() => import("./components/ReservationPages/BuyNow"));
const AccountAccess = lazy(
  () => import("./components/LoginPages/AccountAccess")
);
const Waitlist = lazy(
  () => import("./components/LoginPages/Fulfillments/Waitlist")
);
const LocationStandAlone = lazy(
  () => import("./components/ReservationPages/Segment/LocationStandAlone")
);

const Activation = lazy(() => import("./components/LoginPages/Activation"));
const Summary = lazy(() => import("./components/AccountPages/Summary"));
const Profile = lazy(() => import("./components/AccountPages/Profile"));

const Search = lazy(() => import("./components/ReservationPages/Search"));
const Help = lazy(() => import("./components/AccountPages/Help"));
const IEElevenError = lazy(
  () => import("./components/AccountPages/IEElevenError")
);
const CookiesError = lazy(
  () => import("./components/AccountPages/CookiesError")
);

const Invite = lazy(
  () => import("./components/LoginPages/Fulfillments/Invite")
);
const RewardDetails = lazy(
  () => import("./components/AccountPages/RewardDetails")
);
const ProgramsAndRewards = lazy(
  () => import("./components/AccountPages/ProgramsAndRewards")
);

const airportClients = ["flydenver"];
const nuLocations = ["81354", "155882"];
const emuLocations = ["99960994", "97258"];
const emuNuLocations = [...nuLocations, ...emuLocations];

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const loginPages = ["/login", "/fulfill", "/activate", "/waitlist"];
const publicPages = [
  "/lookup",
  "/search",
  "/search-results",
  "/search-results-sub",
  "/hello",
  "/hello/#resetpassword",
  "/echangepassword",
  "/hello#buynow",
  "/hello/#buynow",
  "/buynow",
  "/subnow",
  "/transactions",
  "/help",
  "/",
  ...airportClients.map((client) => {
    return client.replace("/", "");
  }),
];

const loggedInPages = [
  "/login",
  "/summary",
  "/profile",
  "/subscriptions",
  "/payment-methods",
  "/vehicles",
  "/transactions",
  "/rewards",
  "/buynow",
  "/help",
  ...airportClients.map((client) => {
    return client.replace("/", "");
  }),
];
const externalPages = [
  "/elogin",
  "/ebuynow",
  "/etransactions",
  "/ehelp",
  "/echangepassword",
  "/epayment-methods",
  "/evehicles",
];

const reload = () => window.location.reload();

function checkIfAirport() {
  return airportClients.includes(getAirportClientFromPath());
}

function formatQueryParamsFromInvalidHashLocation() {
  const location = window.location.href;
  if (location.includes("hello") && location.includes("#buynow")) {
    const params =
      window.location.search || location.split("#buynow")?.[1] || "";
    return params;
  } else {
    return window.location.search;
  }
}

const contains = (pages, fullpath) => {
  const pathname = "/" + fullpath.split("/")[1];
  return pages.indexOf(pathname) > -1;
};

const restrictedUrlRedirectBeforeLogin = (contextRoot) => {
  let includesExternalPage = false;
  let urlMatch = "";
  const currentUrl = window.location.href;
  externalPages.forEach((page) => {
    if (currentUrl.includes(page)) {
      urlMatch = page.replace("/", "");
      includesExternalPage = true;
    }
  });
  if (includesExternalPage) {
    return (
      <>
        <Route
          path={[contextRoot + "/login", contextRoot + "/elogin"]}
          component={AccountAccess}
        />
        <Redirect to={contextRoot + "/login?type=login&redirect=" + urlMatch} />
      </>
    );
  } else {
    return (
      <>
        <Route path={contextRoot + "/"} component={Search} />
        <Redirect to={contextRoot + "/"} />
      </>
    );
  }
};

function Routes() {
  const location = useLocation();
  const contextRoot = process.env.REACT_APP_CONTEXT_ROOT;
  const params =
    location.search !== ""
      ? location.search.split("?")[1]
      : location.hash.split("?")[1];
  let values = {};
  try {
    values = qs.parse(params);
  } catch (error) {
    BugsnagManager.notify(error, {
      context: "Unable to parse params in Routes.js",
    });
    console.warn(error);
  }

  function isPayAsYouGo() {
    const pathname = window.location.pathname.split("/").filter((x) => x)[0];

    function stripPathName(path) {
      return path.replace("/", "");
    }

    return (
      pathname === stripPathName(PAYG_TABS.START) ||
      pathname === stripPathName(PAYG_TABS.DASHBOARD) ||
      pathname === stripPathName(PAYG_TABS.ACTIVITY)
    );
  }

  const isAirport = checkIfAirport();

  // Cookies Check
  if (
    location.pathname != null &&
    location.pathname.indexOf("/cookieserror") > -1
  ) {
    return (
      <Suspense fallback={<PageLoader />}>
        <BasePage>
          <Route
            path={contextRoot + "/cookieserror"}
            component={CookiesError}
          />
        </BasePage>
      </Suspense>
    );
  }

  try {
    localStorage.getItem("persist:root");
  } catch (error) {
    window.location.href = contextRoot + "/cookieserror";
  }

  if (
    location.pathname != null &&
    location.pathname.indexOf("/location-details") > -1
  ) {
    return (
      <Suspense fallback={<PageLoader />}>
        <BasePage>
          <Route
            path={contextRoot + "/location-details/:locationId"}
            component={LocationStandAlone}
          />
        </BasePage>
      </Suspense>
    );
  }

  // ie11 check
  let ie11 = !!window.MSInputMethodContext && !!document.documentMode;
  if (!localStorage.getItem("ie11") && ie11) {
    localStorage.setItem("ie11", true);
    window.location.href = contextRoot + "/ieerror";
  }

  if (location?.hash.includes("#resetpassword")) {
    location.pathname = contextRoot + "/echangepassword";
  }

  if (location.pathname?.indexOf?.("/health") > -1) {
    return "true";
  } else if (location.pathname?.indexOf?.("/Pass") > -1) {
    return <Route path="/Pass.html" onEnter={reload} />;
  } else if (location.pathname?.indexOf?.("/ieerror") > -1) {
    return (
      <Suspense fallback={<PageLoader />}>
        <BasePage>
          <Route path={contextRoot + "/ieerror"} component={IEElevenError} />
        </BasePage>
      </Suspense>
    );
  } else if (isPayAsYouGo()) {
    return (
      <Suspense fallback={<PageLoader />}>
        <BasePaygPage>
          <Switch>
            <Route path={PAYG_TABS.START} component={PayAsYouGo} />
            <Route path={PAYG_TABS.DASHBOARD} component={PayAsYouGo} />
            <Route path={PAYG_TABS.ACTIVITY} component={PayAsYouGo} />
          </Switch>
        </BasePaygPage>
      </Suspense>
    );
  } else if (isAirport) {
    return (
      <Suspense fallback={<PageLoader />}>
        <Switch>
          <Route
            path={[
              contextRoot + `/${getAirportClientFromPath()}`,
              contextRoot + `/${getAirportClientFromPath()}/embed`,
            ]}
          >
            {(props) => {
              const isEmbed =
                props.match.path === `/${getAirportClientFromPath()}/embed`;
              return (
                <BaseAirportPage
                  isEmbed={isEmbed}
                  client={getAirportClientFromPath()}
                >
                  <Airport {...props} />
                </BaseAirportPage>
              );
            }}
          </Route>
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Suspense>
    );
  } else if (contains(loginPages, location.pathname.replace(contextRoot, ""))) {
    return (
      <Suspense fallback={<PageLoader />}>
        <BasePage>
          <Switch>
            <Route
              path={[contextRoot + "/login", contextRoot + "/elogin"]}
              component={AccountAccess}
            />
            <Route path={contextRoot + "/fulfill"} component={Invite} />
            <Route path={contextRoot + "/waitlist"} component={Waitlist} />
            <Route path={contextRoot + "/activate"} component={Activation} />
          </Switch>
        </BasePage>
      </Suspense>
    );
  } else if (
    contains(publicPages, location.pathname.replace(contextRoot, ""))
  ) {
    return (
      <Suspense fallback={<PageLoader />}>
        <BasePage>
          <Switch>
            <Route
              path={[contextRoot + "/lookup", contextRoot + "/lookup/*"]}
              component={Lookup}
            />
            <Route
              path={[
                contextRoot + "/transactions",
                contextRoot + "/etransactions",
                contextRoot + "/transactions/*",
                contextRoot + "/etransactions/*",
              ]}
              component={Transactions}
            />
            <Route path={contextRoot + "/search"} component={Search} />
            <Route
              path={[
                contextRoot + "/search-results/:searchTerm/map",
                contextRoot + "/search-results/:searchTerm",
                contextRoot + "/search-results-sub/:searchTerm/map",
                contextRoot + "/search-results-sub/:searchTerm",
              ]}
              component={withGoogleServices(SearchResults)}
            />
            <Route path={contextRoot + "/hello/#resetpassword"}>
              <Redirect
                to={
                  contextRoot +
                  "/echangepassword" +
                  formatQueryParamsFromInvalidHashLocation()
                }
              />
            </Route>
            <Route
              path={contextRoot + "/echangepassword"}
              component={ChangePassword}
            />
            <Route path={contextRoot + "/hello"}>
              <Redirect
                to={
                  contextRoot +
                  "/buynow" +
                  formatQueryParamsFromInvalidHashLocation()
                }
              />
            </Route>
            <Route path={[contextRoot + "/buynow", contextRoot + "/ebuynow"]}>
              {(props) => {
                if (
                  (emuNuLocations.includes(values?.l) && !values?.token) ||
                  values.key
                ) {
                  return <ExternalBuyNow {...props} />;
                } else {
                  return <BuyNow {...props} />;
                }
              }}
            </Route>
            <Route path={contextRoot + "/subnow"} component={SubNow} />
            <Route
              path={[contextRoot + "/help", contextRoot + "/ehelp"]}
              component={Help}
            />
            <Route path={contextRoot + "/"} component={Search} />
          </Switch>
        </BasePage>
      </Suspense>
    );
  } else if (
    RestApi.isSessionValid() &&
    contains(loggedInPages, location.pathname.replace(contextRoot, ""))
  ) {
    return (
      <Suspense fallback={<PageLoader />}>
        <BasePage>
          <Switch>
            <Route path={contextRoot + "/summary"} component={Summary} />
            <Route path={contextRoot + "/profile"} component={Profile} />
            <Route
              path={[
                contextRoot + "/transactions",
                contextRoot + "/etransactions",
                contextRoot + "/transactions/*",
                contextRoot + "/etransactions/*",
              ]}
              component={Transactions}
            />
            <Route
              path={contextRoot + "/subscriptions"}
              component={Subscriptions}
            />
            <Route
              path={[
                contextRoot + "/payment-methods",
                contextRoot + "/epayment-methods",
              ]}
              component={PaymentMethods}
            />
            <Route
              path={[contextRoot + "/vehicles", contextRoot + "/evehicles"]}
              component={Vehicles}
            />
            <Route
              path={contextRoot + "/rewards/reward-programs/:id"}
              component={RewardDetails}
            />
            <Route
              path={contextRoot + "/rewards"}
              component={ProgramsAndRewards}
            />
            <Route path={contextRoot + "/hello"}>
              <Redirect
                to={
                  contextRoot +
                  "/buynow" +
                  formatQueryParamsFromInvalidHashLocation()
                }
              />
            </Route>
            <Route path={[contextRoot + "/buynow", contextRoot + "/ebuynow"]}>
              {(props) => {
                if (
                  (emuNuLocations.includes(values?.l) && !values?.token) ||
                  values.key
                ) {
                  return <ExternalBuyNow {...props} />;
                } else {
                  return <BuyNow {...props} />;
                }
              }}
            </Route>
            <Route
              path={[contextRoot + "/help", contextRoot + "/ehelp"]}
              component={Help}
            />
          </Switch>
        </BasePage>
      </Suspense>
    );
  }
  return (
    <Suspense fallback={<PageLoader />}>
      <BasePage>
        <Switch>{restrictedUrlRedirectBeforeLogin(contextRoot)}</Switch>
      </BasePage>
    </Suspense>
  );
}

export default Routes;
